<template>
  <el-dialog class="recall-task-box" width="80%" :title="title" :visible.sync="dialogFormVisible">
    <el-table
      :data="recallData"
    >
      <el-table-column
        label="活动名称"
        prop="sceneTitle">
      </el-table-column>
      <el-table-column label="活动类型" align="center" width="100">
        <template slot-scope="scope">
          {{scope.row.sceneType | sceneTypeFilter}}
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        width="300"
        label="任务规则">
        <template slot-scope="scope">
          <div>
            代表执行{{scope.row.representValidDay}}天+医生学习{{scope.row.validDay}}天
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop=""
        align="center"
        label="可撤回任务数">
        <template slot-scope="scope">
          <div>
            可撤回{{scope.row.canRecallCodeCount || 0}}任务
          </div>
        </template>
      </el-table-column>
        <el-table-column
            label="任务来源"
            prop="sourceOrgName"
            align="center"
        ></el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <div class="operate-box">
            <span class="pdg-r3">撤回</span>
            <el-input-number v-model="scope.row.selectNum" @change="handleChange" size="small" :min="0" :max="scope.row.canRecallCodeCount" label="描述文字"></el-input-number>
            <span class="pdg-l3">个</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="total-task-box">
      <div>本次撤回任务：<span class="task-count">{{taskCount}} 个</span></div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')">确认撤回</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "Addorginazation",
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        return this.isShowPop
      },
      set(val) {
        this.open = val
        this.$emit('onClose',val)
      }
    },
    recallData: {
      get() {
        return this.tableData
      },
      set(list) {
        let data = []
        list?.map(item => {
          data.push({...item,selectNum: 0})
        })
        this.tableData = data
      }
    },
    recallNum() {
        return this.recallData.length
    }
  },
  watch: {
    recallData: {
      deep: true,
      handler(newVal) {
        this.taskCount = 0
        this.taskGift = 0
        newVal?.map(item => {
          this.taskCount += item.selectNum
          this.taskGift += item.selectNum * item.money
        })
      }
    }
  },
  data() {
    return {
      open: false,
      title: '撤回任务',
      tableData: [],
      loading: false,
      taskCount: 0,
      taskGift: 0,
    }
  },
  filters: {
    sceneTypeFilter(type) {
      switch(type) {
        case 1:
          return '科室会'
        case 2:
          return '旧调研'
        case 3:
          return '旧拜访'
        case 4:
          return '问卷调研'
        case 5:
          return '定向直播'
        case 6:
          return '多人会议'
        case 7:
          return '员工培训'
        case 8:
          return '学术拜访'
        case 9:
          return '代表知识商城'
        case 10:
          return '知识店铺'
        case 12:
          return '医学播客'
        default:
          return '一'
      }
    }
  },
  methods: {
    ...mapActions(['recallResource','recallRepresentCode']),
    submitForm(formName) {
      if(this.loading) return
      this.loading = true
      let data = []
      this.recallData?.map(item => {
        data.push({
          sceneId: item.sceneId,
          sceneType: item.sceneType,
          money: item.money,
          validDay: item.validDay,
          representValidDay: item.representValidDay,
          purchaseId: item.sourceOrgId,
          finalOrgId: this.formObj.orgId,
          isPreDoctorCode: item.isPreDoctorCode,
          representId: this.formObj.representId,
          recallCount: item.selectNum,
        })
      })
      this.recallRepresentCode(data).then(res => {
        this.dialogFormVisible = false;
        this.loading = false
        this.$message({
          message: '撤回成功',
          type: 'success'
        })
        this.$emit('onRecallConfirm')
      },rea => {
        this.loading = false
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    handleChange(val) {
      console.log(val,this.recallData)
    },
    onGetRecallList() {
      console.log(this.formObj)
      let data = {
        sceneId: this.formObj.sceneId,
        sceneType: this.formObj.sceneType,
        money: this.formObj.money,
        validDay: this.formObj.validDay,
        representValidDay: this.formObj.representValidDay,
        orgId: this.formObj.orgId,
        isPreDoctorCode: this.formObj.isPreDoctorCode,
        representId: this.formObj.representId,
      }
      this.recallResource(data).then(res => {
        this.recallData = res.data || []
      },rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    }
  },
  mounted() {
    this.form = Object.assign({},this.form,this.formObj)
    this.onGetRecallList()
  }
}
</script>

<style lang="scss" scoped>
.recall-task-box {
  ::v-deep .el-dialog .el-dialog__header {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0F0F0F;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
}
.dialog-footer {
  text-align: center;
}

.operate-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.total-task-box {
  text-align: right;
  padding: 10px;
  color: #5A5A5A;
  font-size: 12px;
}
.task-count {
  color: #0F0F0F;
  font-weight: bold;
}
.task-gift {
  color: #FF9100;
  font-size: 20px;
}
.task-text {
  color: #FF9100;
  font-weight: bold;
}

.pdg-l3 {
  padding-left: 3px;
}
.pdg-r3 {
  padding-right: 3px;
}
</style>
